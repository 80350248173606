// regular style toast
@import '~ngx-toastr/toastr';
@import '~animate.css/animate.css';
@import "~swiper/swiper-bundle";
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-body-cell-label,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group .datatable-body-cell-label,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-body-cell-label,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group .datatable-body-cell-label,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-body-cell-label,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group .datatable-body-cell-label {
  color: #fefefe !important;
}
.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: #145388 !important;
}
.ngx-datatable .datatable-header {
  margin-bottom: 0px !important;
  height: 45px !important;
}
.ngx-datatable .datatable-body .datatable-body-row {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-bottom: 50px !important;
}

body.dark-mode .has-float-label label::after, body.dark-mode .has-float-label > span::after {
  background: #333333 !important;
}

body.dark-mode .has-float-label label, body.dark-mode .has-float-label > span:last-of-type {
  color: #8f8f8f;
}

body.dark-mode .form-control {
  background: #232223;
  color: #8f8f8f;
}

body.dark-mode .card.auth-card {
  background: #1e2022;
}

body.dark-mode .navbar {
  background: #242424;
}
body.dark-mode .menu .sub-menu {
  background: #242424;
  border-left: 1px solid #313131;
}
body.dark-mode .menu .main-menu {
  background: #242424;
}

body.dark-mode .menu .main-menu ul li a,
body.dark-mode .menu .sub-menu ul li a {
  color: #8f8f8f;
}

.k-grid tr.dragging {
  background-color: #f45c42;
};

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; padding-bottom: 0px !important; }

.cdk-overlay-pane {
  flex: 0 1 90% !important;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

.cdk-overlay-container {
  z-index: 9999;
}

.mat-dialog-container {
  overflow: hidden !important;
}

.custom-dialog-container .mat-dialog-container { padding: 24px !important; }

.mat-dialog-container {
  padding: 0px !important;
}

.full-height {
  height: 100vh !important;
}

.mat-dialog-container {
  position: relative !important;
}

.blockOverlay, .blockMsg  {
  z-index: 9999 !important;
}

main .container-fluid {
  height: 100% !important;
}

.ngx-toastr {
  width: 100% !important;
}

@media screen and (max-width: 990px) {
  .hidden-on-mobile { display: none !important; }
}

@media screen and (min-width: 991px) {
  .visible-on-mobile { display: none !important; }
}

@media screen and (max-width: 1024px) {
  .cdk-overlay-pane {
    flex: 0 1 90% !important;
    position: absolute !important;
    bottom: 0 !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 9999999 !important;
    max-width: 100% !important;
  }
}


#design-workflows-menu {
  .app-menu {
    overflow-y: auto;
  }
  ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
}

.abw-execution-dialog {
  .card {
    .position-relative {
      background: #e8eaf0;
      margin-bottom: 15px;

      p {
        margin-bottom: 0px !important;
      }
    }
  }

  label {
    border-radius: 10px;
    padding: 4px;
    color: gray;
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
  }

  input:read-only {
    color: #c5c9d5 !important;
  }

}

.cdk-global-overlay-wrapper {
  .cdk-overlay-pane {
    overflow-y: auto;
  }
}

code-input {
  input {
    background: black !important;
    color: white !important;
  }
}

.cfc-error-message {
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545;
}

.ngx-ptr-spinner {
  /*display: none !important;*/
}

.k-datetime-container .k-date-tab .k-datetime-selector, .k-datetime-container .k-time-tab .k-datetime-selector {
  height: 280px !important;
}
.k-datetime-container {
  height: auto !important;
}

.ngx-ptr-content-container {
  overflow-x: hidden;
  overflow-y: scroll;
}